<template>
  <div>
    <b-row>
      <b-col>
        <h4>Документы</h4>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-table :fields="fields" :items="docs">
          <template #cell(file)="data">
            <a :href="data.value" target="_blank" class="btn btn-primary">
              <b-icon-download />
            </a>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { $getDocs } from "@/api";

export default {
  name: "DashDocs",
  data() {
    return {
      docs: [],
      fields: [
        {
          key: "title",
          label: "Название"
        },
        {
          key: "text",
          label: "Описание"
        },
        {
          key: "doc_type_display",
          label: "Тип"
        },
        {
          key: "file",
          label: "Скачать"
        }
      ]
    };
  },
  created() {
    this.getDocs();
  },
  methods: {
    async getDocs() {
      try {
        const res = await $getDocs();
        this.docs = res.data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
