<template>
  <div class="home">
    <b-container fluid>
      <b-row align-v="center">
        <b-col md="5" class="text-center">
          <h1 class="text-center">Парус<br />Сити</h1>
          <p class="text-center mt-4">
            Сайт товарищества собственников "Парус Сити".<br />
            Основной функционал доступен собственникам и арендаторам
            недвижимости
          </p>
          <div class="login-button">
            <b-button
              :to="{ name: 'DashInfo' }"
              block
              variant="outline-primary"
              size="lg"
              pill
              v-if="loggedIn"
            >
              Личный кабинет
            </b-button>
            <b-button
              block
              variant="outline-primary"
              size="lg"
              pill
              v-b-modal.loginModal
              v-else
            >
              Войти
            </b-button>
          </div>
        </b-col>
        <b-col md="7" class="d-none d-md-block text-right img-col">
          <img src="../assets/img/bg.jpg" class="home-img" />
        </b-col>
      </b-row>
      <b-row class="mt-4 d-none d-xs-block d-sm-block d-md-none">
        <b-col>
          <b-row align-v="start">
            <b-col class="d-flex">
              <div class="home-news-item">
                <h3 class="home-news-title">Счетчики тепла</h3>
                <p class="home-news-text">
                  С ноября 2020 года в доме установлены счетчики поквартирного
                  учета тепла.
                </p>
                <span class="home-news-date">01.12.2020</span>
              </div>
              <div class="home-news-item">
                <h3 class="home-news-title">Общее собрание</h3>
                <p class="home-news-text">
                  По итогам общего собрания 21.08.2020 избран новый председатель
                  и члены правления товарищества.
                </p>
                <span class="home-news-date">01.09.2020</span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <div class="home-fixed d-none d-md-block">
      <b-container fluid>
        <b-row>
          <b-col md="5" align-self="end">
            <p class="text-center">
              г. Минск, ул. Кальварийская, 16
            </p>
          </b-col>
          <b-col md="7" align-self="start" class="text-right">
            <b-row align-v="start">
              <b-col class="d-flex justify-content-end">
                <div class="home-news-item" v-for="item in news" :key="item.id">
                  <h3 class="home-news-title">{{ item.title }}</h3>
                  <p class="home-news-text">
                    {{ item.text }}
                  </p>
                  <span class="home-news-date">{{ item.created }}</span>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="loginModal" title="Вход" centered hide-footer>
      <login-modal />
    </b-modal>
  </div>
</template>

<script>
import LoginModal from "@/components/LoginModal";
import { $getNews } from "@/api";

export default {
  name: "Home",
  components: { LoginModal },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    }
  },
  data() {
    return {
      news: []
    };
  },
  created() {
    this.getNews()
  },
  methods: {
    async getNews() {
      try {
        const res = await $getNews();
        this.news = res.data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
