<template>
  <div class="topbar">
    <b-navbar toggleable="md">
      <b-navbar-brand>
        <router-link :to="{ name: 'Home' }">
          <img src="../assets/img/logo.png" class="topbar-logo" />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{ name: 'Home' }">Главная</b-nav-item>
          <b-nav-item :to="{ name: 'Contacts' }">Контакты</b-nav-item>
          <!--          <b-nav-item href="#">Квартиры</b-nav-item>-->
          <!--          <b-nav-item href="#">Офисы</b-nav-item>-->
          <b-nav-item :to="{ name: 'About' }">О Парусе</b-nav-item>
          <b-nav-item :to="{ name: 'DashInfo' }"
                      v-if="loggedIn">
            Личный кабинет
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    }
  }
};
</script>
