<template>
  <div>
    <b-form-group label="Номер телефона">
      <b-input-group prepend="+">
        <b-form-input
          v-model="phone"
          placeholder="375000000000"
          type="phone"
          required
        />
      </b-input-group>
    </b-form-group>
    <b-form-group label="Код" v-if="sent">
      <b-form-input
        v-model="code"
        placeholder="000000"
        type="number"
        required
      />
    </b-form-group>
    <b-form-group class="text-center">
      <b-button variant="primary" v-if="!sent" @click="sentCode" pill>
        Получить код
      </b-button>
      <b-button v-else @click="login" pill>
        Войти
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import { $sendCode, $login } from "@/api";

export default {
  name: "LoginModal",
  data() {
    return {
      phone: "",
      code: "",
      sent: false
    };
  },
  methods: {
    async sentCode() {
      try {
        const res = await $sendCode(this.phone);
        console.log(res.data)
        this.sent = true;
      } catch (e) {
        this.$bvToast.toast("Ошибка при отправке кода", {
          title: "Ошибка",
          variant: "danger"
        });
        this.sent = false;
      }
    },
    async login() {
      try {
        const res = await $login(this.phone, this.code);
        this.$store.commit("setUser", res.data);
        this.$store.commit("logIn");
        this.$bvModal.hide("loginModal");
        await this.$router.push({ name: "DashInfo" });
      } catch (e) {
        this.$bvToast.toast("Ошибка при входе", {
          title: "Ошибка",
          variant: "danger"
        });
      }
    }
  }
};
</script>
