<template>
  <div class="home">
    <b-container fluid>
      <b-row class="d-flex align-items-center">
        <b-col md="5" class="text-center">
          <h1>Парус Сити</h1>
          <p class="mt-4">
            "Парус" - первый белорусский жилой 34-этажный небоскреб.<br>
            Самое высокое здание Беларуси (до ввода в эксплуатацию ЖК "Лазурит").<br>
            Находится на пересечении улиц Тимирязева, М.Танка и Кальварийской.
            Рядом расположены станции метро "Фрунзенская", "Молодёжная", "Юбилейная площадь".
          </p>
        </b-col>
        <b-col md="7" class="d-none d-md-block text-right img-col">
          <img src="../assets/img/bg.jpg" class="home-img">
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>