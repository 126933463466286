<template>
  <div class="home">
    <b-container fluid class="mt-4">
      <b-row align-v="center">
        <b-col md="5" class="text-center">
          <h2>г. Минск, ул. Кальварийская, 16</h2>
        </b-col>
        <b-col md="7">
          <div style="position:relative;overflow:hidden;">
            <a
              href="https://yandex.by/maps/157/minsk/?utm_medium=mapframe&utm_source=maps"
              style="color:#eee;font-size:12px;position:absolute;top:0px;"
              >Минск</a
            ><a
              href="https://yandex.by/maps/157/minsk/house/Zk4YcwRiTEYAQFtpfXVxd3tkZQ==/?ll=27.533735%2C53.906868&utm_medium=mapframe&utm_source=maps&z=17.4"
              style="color:#eee;font-size:12px;position:absolute;top:28px;"
              >Кальварийская улица, 16 — Яндекс.Карты</a
            ><iframe
              src="https://yandex.by/map-widget/v1/-/CCUQNMXS2D"
              width="100%"
              height="500"
              frameborder="0"
              allowfullscreen="true"
              style="position:relative;"
            ></iframe>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Contacts'
}
</script>