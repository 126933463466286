<template>
  <div>
    <b-row>
      <b-col>
        <label>Имя</label>
        <h4>{{ user.firstname }} {{ user.lastname }}</h4>
      </b-col>
      <b-col>
        <label>Телефон</label>
        <h4>+{{ user.username }}</h4>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <label>Квартиры:</label>
        <h5 v-for="item in flats" :key="item.id">
          #{{ item.number }} - {{ item.size }}m<sup>2</sup> -
          {{ item.floor }} эт.
        </h5>
      </b-col>
      <b-col>
        <label>Парковки:</label>
        <h5 v-for="item in parkings" :key="item.id">
          #{{ item.number }} - {{ item.size }}m<sup>2</sup>
        </h5>
      </b-col>
      <b-col>
        <label>Офисы:</label>
        <h5 v-for="item in offices" :key="item.id">
          #{{ item.number }} - {{ item.size }}m<sup>2</sup> -
          {{ item.floor }} эт.
        </h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { $getEstate } from "@/api";

export default {
  name: "DashInfo",
  data() {
    return {
      estate: []
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    flats() {
      return this.estate.filter((item) => item.estate_type === 0);
    },
    offices() {
      return this.estate.filter((item) => item.estate_type === 1);
    },
    parkings() {
      return this.estate.filter((item) => item.estate_type === 2);
    }
  },
  created() {
    this.getEstate();
  },
  methods: {
    async getEstate() {
      try {
        const res = await $getEstate();
        this.estate = res.data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
