<template>
  <div>
    <b-container class="mt-4">
      <b-row>
        <b-col>
          <h2>Личный кабинет</h2>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-card no-body>
            <b-card-header header-tag="nav">
              <b-nav card-header tabs>
                <b-nav-item exact exact-active-class="active" :to="{ name: 'DashInfo' }">
                  Ваши данные
                </b-nav-item>
                <b-nav-item exact exact-active-class="active" :to="{ name: 'DashDocs' }">
                  Документы
                </b-nav-item>
                <b-nav-item exact exact-active-class="active" :to="{ name: 'DashVotes' }">
                  Голосование
                </b-nav-item>
              </b-nav>
            </b-card-header>
            <b-card-body>
              <b-card-text>
                <router-view />
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>