import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loggedIn: false,
    user: {}
  },
  mutations: {
    logIn(state) {
      state.loggedIn = true;
    },
    setUser(state, user) {
      state.user = user;
      console.log(user)
    },
    logOut(state) {
      state.loggedIn = false;
      state.user = {}
    }
  },
  plugins: [createPersistedState()]
});

export default store;
