<template>
  <div id="app">
    <top-bar />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import { $getMe } from "@/api";

export default {
  name: "App",
  components: { TopBar },
  created() {
    this.getMe()
  },
  methods: {
    async getMe() {
      try {
        const res = await $getMe();
        this.$store.commit("logIn");
        this.$store.commit("setUser", res.data);
      } catch (e) {
        this.$store.commit("logOut");
      }
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
