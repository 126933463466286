import VueRouter from "vue-router";

import Home from "@/views/Home";
import About from "@/views/About";
import Contacts from "@/views/Contacts";
import Dashboard from "@/views/Dashboard";
import DashInfo from "@/views/DashInfo";
import DashDocs from "@/views/DashDocs";
import DashVotes from "@/views/DashVotes";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "DashInfo",
        component: DashInfo
      },
      {
        path: "/docs",
        name: "DashDocs",
        component: DashDocs
      },
      {
        path: "/votes",
        name: "DashVotes",
        component: DashVotes
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
