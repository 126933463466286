import axios from "axios";
import router from "@/routes";
import store from "@/store";

const $backend = axios.create({
  baseURL: "http://parus.by",
  timeout: 5000,
  xsrfHeaderName: "x-csrf-token",
  headers: {
    "Content-Type": "application/json"
  }
});

$backend.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 403) {
      store.commit("logOut");
      router.push({ name: "Home" });
    }
    return Promise.reject(error);
  }
);

export const $sendCode = async phone => {
  return $backend.post("/send-code", { phone: phone });
};

export const $login = async (phone, code) => {
  return $backend.post("/login", { phone: phone, code: code });
};

export const $getMe = async () => {
  return $backend.get("/me");
};

export const $getNews = async () => {
  return $backend.get("/news");
};

export const $getEstate = async () => {
  return $backend.get("/estates");
};

export const $getDocs = async () => {
  return $backend.get("/docs");
};
