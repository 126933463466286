import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import router from "@/routes";
import store from "@/store";

import "./assets/scss/main.scss";

Vue.config.productionTip = false;

Vue.use(VueRouter)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
